import React from 'react';
import dayjs from 'dayjs';
import { capitalize } from 'lodash';

import formatAddress from 'utils/formatAddress';

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0
});

const getClosingDate = value => {
  const date = dayjs(value);
  return value && date.isValid() ? date.format('MM/DD/YY') : '-';
};

const RecentProjectItem = ({ row, clientData, isMobile, onClick }) => {
  const { closingDate, currentPrice } = row;
  const status = row.status || row.mlsStatus;
  // eslint-disable-next-line no-nested-ternary
  const statusCellClassName = status === 'Active' ? 'green' : status === 'Closed' ? 'red' : '';

  const layout = (
    <tr className="recent-project-item" onClick={onClick}>
      <td>{formatAddress(row)}</td>
      <td>{clientData.roles[row.id]}</td>
      <td className={statusCellClassName}>{status}</td>
      <td>{currencyFormatter.format(currentPrice)}</td>
      <td>{getClosingDate(closingDate)}</td>
    </tr>
  );

  const mobileLayout = (
    <tbody className="recent-project-item" onClick={onClick}>
      <tr className="firstItem">
        <td>Property Name</td>
        <td>{formatAddress(row)}</td>
      </tr>
      <tr>
        <td>Position</td>
        <td>{clientData.roles[row.id]}</td>
      </tr>
      <tr>
        <td>Status</td>
        <td className={statusCellClassName}>{status}</td>
      </tr>
      <tr>
        <td>Closing Date</td>
        <td>{getClosingDate(closingDate)}</td>
      </tr>
      <tr className="lastItem">
        <td>Purchase Price</td>
        <td>{currencyFormatter.format(currentPrice)}</td>
      </tr>
    </tbody>
  );

  return isMobile ? mobileLayout : layout;
};

export default React.memo(RecentProjectItem);
