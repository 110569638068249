import React, { useContext, useState, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { styled } from 'styles';
import Button from 'components/Button';
import Input from 'components/Input';
import Pill from 'components/Pill';
import { REGEXP } from 'consts';
import Context from '../context';

const SendMessage = () => {
  const { messageModalData, users, sendMessage } = useContext(Context);
  const [contacts, setContacts] = useState<Array<Partial<Client>>>([]);
  const { register, setValue, errors, clearErrors, trigger, getValues } = useForm({
    defaultValues: {
      email: messageModalData?.email || '',
      subject: '',
      message: ''
    }
  });

  const contactEmails = useMemo(() => contacts?.map(contact => contact.email), [contacts]);

  const handleAddEmail = async () => {
    const email = getValues('email');
    const isValid = await trigger('email');

    if (isValid) {
      addEmail(email);
      clearErrors();
      setValue('email', '');
    }
  };

  const addEmail = email => {
    const existingUser = users.find(user => user.email === email);
    const name = existingUser && existingUser.name ? existingUser.name : '';

    setContacts(v => [...v, {
      name, email
    }]);
  };

  const deleteEmail = email => {
    setContacts(v => v.filter(contact => contact.email !== email));
  };

  const onSubmit = () => {
    const { subject, message } = getValues();
    if (message && contactEmails.length) {
      sendMessage({ subject, message, email: contactEmails.join(',') });
    } else {
      trigger(['email', 'message']);
    }
  };

  const renderPills = () =>
    contacts.length > 0 && (
      <div className="addressee">
        <div className="pill-container">
          {contacts.map((contact, idx) => (
            <Pill key={idx} onCancelClick={() => deleteEmail(contact.email)}>
              {contact.name || contact.email}
            </Pill>
          ))}
        </div>
      </div>
    );

  return (
    <StyledSendMessage className="message-box">
      <form onSubmit={e => e.preventDefault()}>
        <label>To</label>
        <div className="add-email-control">
          <Input
            ref={register({
              required: true,
              pattern: {
                value: REGEXP.EMAIL,
                message: ''
              },
              validate: value => !contactEmails?.includes(value)
            })}
            name="email"
            error={Boolean(errors.email) && !contactEmails.length}
            className="email-field"
            placeholder="Enter"
            onBlur={handleAddEmail}
            data-cy="input_email"
          />
          <span
            role="button"
            className="add-email-addon"
            onClick={handleAddEmail}
            data-cy="button_add_email">
            + Add
        </span>
        </div>
        {renderPills()}
        <label>Subject</label>
        <Input
          ref={register}
          name="subject"
          className="title-field"
          placeholder="Enter"
          data-cy="input_subject"
        />
        <label>Message</label>
        <Input
          ref={register({ required: 'Required' })}
          name="message"
          as="textarea"
          error={Boolean(errors.message)}
          className="message-field"
          placeholder="Write message..."
          data-cy="input_textarea_message"
        />
        <div className="button-container">
          <Button
            onClick={onSubmit}
            className="btn-send"
            data-cy="button_submit"
          >
            Send Message
          </Button>
        </div>
      </form>
    </StyledSendMessage>
  );
};

export default React.memo(SendMessage);

export const StyledSendMessage = styled.div`
  width: 324px;
  max-width: 100%;

  .input {
    display: block;
    height: 40px;
  }

  .add-email-control {
    position: relative;

    input {
      padding-right: 60px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .add-email-addon {
      display: inline-flex;
      padding: 14px 8px;
      position: absolute;
      right: 0;
      top: 0;
      font-size: 12px;
      font-weight: bold;
      color: ${props => props.theme.colors.red};
      text-transform: uppercase;
      cursor: pointer;

      .icon {
        width: 10px;
        height: 10px;
        margin-right: 7px;
        fill: ${props => props.theme.colors.red};
      }
    }
  }

  .addressee {
    margin: -8px 0 8px;
    display: flex;

    .pill-container {
      margin: 0;
      & > span {
        display: inline-flex;
        padding: 4px 2px 4px 4px;
        font-size: 12px;
        line-height: 16px;
        background: white;
        border: 1px solid black;
        border-radius: 3px;
      }

      .cancel-btn {
        margin-left: 2px;
      }
      .cancel-btn .icon {
        width: 8px;
        height: 8px;
      }
    }

    .pill-label {
      line-height: 26px;
      height: 26px;
    }
  }

  .input {
    margin-bottom: 16px;
  }

  .message-field {
    height: auto;
  }

  textarea {
    box-sizing: border-box;
    width: 100%;
    height: 160px;
    padding: 12px 8px;
    font-size: 12px;
    line-height: 16px;
    border: 1px solid ${props => props.theme.colors.seashell};
    border-radius: ${props => props.theme.misc.borderRadius};
    resize: none;
    outline: none;

    &:hover,
    &:focus {
      border-color: black;
    }
  }

  label {
    display: block;
    margin-bottom: 4px;
    font-size: 10px;
    line-height: 16px;
    color: ${props => props.theme.colors.grayDark};
  }

  .button-container {
    margin-top: 16px;
    .btn-send {
      width: 100%;
      height: 40px;
      padding: 12px 8px;
      border-radius: 3px;
      font-size: 12px;
      line-height: 16px;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 100%;
  }
`;
