import { fetchWithToken } from 'utils/fetch';
import { mapListings } from 'utils/mapping';
import { urlEncodeBody } from 'utils/requests';

export const getPeople = async (): Promise<Array<Client>> => {
  const response = await fetchWithToken('/people');
  const data = await response.json();

  return data.map(item => ({
    id: item.id,
    name: item.name,
    email: item.email,
    photo: item.photo,
    roles: item.roles,
    position: item.roles ? Array.from(new Set(Object.values(item.roles))) : [],
    pvalue: item.pvalue,
    revenueYTD: item.ytd_revenue,
    revenueTotal: item.total_revenue,
    transactionsOpen: item.transactions_open,
    transactionsClosed: item.transactions_closed
  }));
};

export const getClientListings = async (id: number) => {
  const response = await fetchWithToken(`/people/listings/${id}`);
  const data = await response.json();

  return mapListings(data.listings);
};

export const contactClient = async values => {
  await fetchWithToken(`/people/send`, { method: 'POST', body: urlEncodeBody(values) });
};
