import React, { useState } from 'react';
import clsx from 'clsx';

import { styled } from 'styles';
// import Avatar from 'components/Avatar';
import Icon from 'components/Icon';
import ClientDetails from './ClientDetails';

interface PeopleListItemProps {
  data: Client;
  onOpen?: () => void;
}

const PeopleListItem: React.FC<PeopleListItemProps> = ({ data, onOpen }) => {
  const [openDetails, setOpenDetails] = useState<boolean>(false);
  const { /* photo, */ name, email, position, transactionsOpen, transactionsClosed } = data;

  const handleOpenClick = () => {
    onOpen?.();
    setOpenDetails(v => !v);
  };

  return (
    <>
      <StyledPeopleListItem
        className={clsx('people-list__item', { 'people-list__item__open': openDetails })}
        onClick={handleOpenClick}>
        <div className="people-list__item-info people-list__item-name">
          <Icon name="plus-mini" fill="#000000" />
          {/* <Avatar src={photo} className="user-img" /> */}
          {name || email}
        </div>
        <div className="people-list__item-info people-list__item-position">
          {position?.join(', ') || '-'}
        </div>
        <div className="people-list__item-info people-list__item-active">
          {transactionsOpen || '-'}
        </div>
        <div className="people-list__item-info people-list__item-closed">
          {transactionsClosed || '-'}
        </div>
        {/* <div className="people-list__item-info people-list__item-pvalue">
          <span>{pvalue || '-'}</span>
        </div>
        <div className="people-list__item-info people-list__item-value">
          <span>{revenueYTD || '-'}</span>
        </div>
        <div className="people-list__item-info people-list__item-revenue">
          <span>{revenueTotal || '-'}</span>
        </div> */}
      </StyledPeopleListItem>
      {openDetails && <ClientDetails data={data} />}
    </>
  );
};

export default React.memo(PeopleListItem);

const StyledPeopleListItem = styled.div`
  display: flex;
  .icon {
    flex-shrink: 0;
    transition: all 300ms;
  }
  &.people-list__item__open {
    .icon-plus-mini {
      transform: rotate(45deg);
      fill: ${props => props.theme.colors.red};
    }
  }
  .icon-plus-mini {
    margin-right: 12px;
  }
`;
