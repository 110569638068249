import React, { useContext } from 'react';
import pluralize from 'pluralize';

import { styled } from 'styles';
import useLayout from 'hooks/useLayout';
import noPhoto from 'assets/images/avatar.jpg';
import { WorkspaceContext } from 'workspace';
import RecentProjectItem from './RecentProjectItem';

interface RecentProjectsProps {
  data: Client;
}

const RecentProjects: React.FC<RecentProjectsProps> = ({ data }) => {
  const { openWorkspace } = useContext(WorkspaceContext);
  const { listings = [] } = data;
  const listingsLength = listings.length;
  const isMobile = useLayout() === 'mobile';
  const photoSrc = data.photo || noPhoto;
  const bgStyle = { backgroundImage: `url(${photoSrc})` };

  return (
    <RecentProjectsContainer>
      <div className="section">
        <div className="user-container">
          <div className="avatar" style={bgStyle} />
          <div className="user-info">
            {data.name && <h3 className="name">{data.name}</h3>}
            {data.email && (
              <a href={`mailto:${data.email}`} className="email">
                {data.email}
              </a>
            )}
          </div>
        </div>
        <div className="counter-container">
          <h4>Recent Projects</h4>
          <div className="counters">
            <div className="counter">
              <span>{listingsLength}</span>
              <span>{pluralize('Project', listingsLength)}</span>
            </div>
            <div className="counter">
              <span>{listings.filter(project => project.mlsStatus === 'Active').length}</span>
              <span>Active</span>
            </div>
            <div className="counter">
              <span>{listings.filter(project => project.mlsStatus === 'Closed').length}</span>
              <span>Closed</span>
            </div>
          </div>
        </div>
      </div>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>Property Name</th>
              <th>Position</th>
              <th>Status</th>
              <th>Purchase Price</th>
              <th>Closing Date</th>
            </tr>
          </thead>
          <tbody>
            {listings.map(row => (
              <RecentProjectItem
                key={row.id}
                row={row}
                clientData={data}
                onClick={() => openWorkspace(row)}
                isMobile={isMobile}
              />
            ))}
          </tbody>
        </table>
      </div>
    </RecentProjectsContainer>
  );
};

export default React.memo(RecentProjects);

const RecentProjectsContainer = styled.div`
  box-sizing: border-box;
  width: 1024px;
  max-width: 100%;
  height: 100%;
  font-size: 12px;
  line-height: 16px;

  .user-container,
  .counter-container {
    display: flex;

    .counter {
      margin-left: 40px;
    }
  }

  .name {
    margin-bottom: 4px;
    font-size: 16px;
    font-weight: bold;
    line-height: 32px;
    background: ${props => props.theme.colors.white};

    &.scroll {
      position: sticky;
      top: 0;
      padding-bottom: 8px;
      height: 0;
      overflow: hidden;
      background: ${props => props.theme.colors.white};
      transition: all 300ms ease;
      &.visible {
        height: 40px;
      }
    }
  }

  .user-container {
    margin-bottom: 40px;

    .avatar {
      width: 124px;
      height: 124px;
      margin-right: 16px;
      border-radius: 3px;
      overflow: hidden;
      background-color: ${props => props.theme.colors.gray};
      display: inline-flex;
      background-size: cover;

      .icon {
        fill: ${props => props.theme.colors.grayDark};
        margin: auto;
      }
    }
    .user-info {
      display: flex;
      flex-direction: column;

      span,
      a {
        display: block;

        &.email {
          color: ${props => props.theme.colors.grayDark};
          text-decoration: none;
        }
      }
    }
  }

  .counter-container {
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;
    background: #f8f8f8;
    border-radius: 3px;

    h4 {
      margin: 0;
      font-size: 16px;
      font-weight: normal;
      line-height: 32px;
    }

    .counters {
      display: flex;
    }

    .counter {
      &:first-of-type {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      span {
        display: block;
        font-weight: normal;
        font-size: 12px;
        color: ${props => props.theme.colors.grayDark};

        &:first-of-type {
          font-weight: bold;
          font-size: 16px;
          line-height: 32px;
          color: black;
        }
      }
    }
  }
  .section {
    padding: 0;
  }
  .table-container {
    margin-top: 15px;
    height: calc(100% - 240px);
    overflow: auto;
  }
  table {
    width: 100%;
    border-collapse: collapse;

    th {
      text-align: left;
      padding: 12px 0;
    }

    thead th {
      position: sticky;
      color: ${props => props.theme.colors.grayDark};
      background: white;
      font-size: 12px;
      font-weight: normal;
      top: 0;
    }
    tbody tr {
      font-size: 12px;

      td {
        border-top: 1px solid ${props => props.theme.colors.seashell};
        padding: 12px 0;
        a {
          color: black;
          text-decoration: none;
        }
      }

      .red {
        color: ${props => props.theme.colors.errorRed};
      }

      .green {
        color: ${props => props.theme.colors.lightGreen};
      }
    }
    .recent-project-item {
      cursor: pointer;
    }
    &.scroll {
      thead {
        display: table;
        width: calc(100% - 1em);
        table-layout: fixed;
      }
      thead th {
        padding-right: 5px;
        &:first-of-type {
          width: 50%;
        }
      }
      tbody {
        display: block;
        height: 366px;
        overflow: auto;

        tr {
          display: table;
          width: 100%;
          table-layout: fixed;

          td {
            padding-right: 5px;
            &:first-of-type {
              width: 40%;
            }
          }
        }
      }
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    box-sizing: border-box;

    .user-container {
      margin-bottom: 32px;
      flex-direction: column;

      .avatar {
        width: 64px;
        height: 64px;
        margin-bottom: 8px;
      }
    }

    .counter-container {
      flex-direction: column;
      align-items: flex-start;

      .counters {
        flex-direction: column;
        margin-top: 12px;
        width: 100%;

        .counter {
          margin: 0;
          display: flex;
          flex-direction: row-reverse;
          justify-content: space-between;

          &:first-of-type {
            font-size: 12px;
            font-weight: normal;
            line-height: 16px;
          }
        }
      }
    }

    .section {
      .user-container,
      .counter-container {
        padding-top: 4px;
      }

      .counter-container {
        justify-content: space-around;
      }

      .user-container {
        padding-top: 0;
      }
    }

    .table-container {
      height: auto;
    }

    table {
      thead {
        display: none;
      }

      tbody {
        tr {
          border-top: none;

          td {
            &:first-of-type {
              color: ${props => props.theme.colors.grayDark};
              width: 35%;
            }
          }

          &.firstItem td {
            padding-top: 25px;
          }

          &.lastItem {
            border-bottom: 1px solid ${props => props.theme.colors.seashell};

            td {
              padding-bottom: 25px;
            }
          }
        }
      }

      &.scroll {
        thead {
          display: none;
        }
        tbody {
          height: calc(100vh - 280px);
        }
      }
    }
  }
`;
